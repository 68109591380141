import { Typography } from "antd";
import { SUBHEADER_ID } from "../App";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import Flex from "../utils/Flex";
import { FavouriteButton } from "./Law";
import styles from "./Subheader.module.scss";

export interface SubheaderProps {
  title?: string;
  hideFavourite?: boolean;
  hideFavouriteText?: boolean;
  onFavorite?: (fav: boolean) => void;
  favorite?: boolean;
}

export const Subheader = ({
  title,
  hideFavourite,
  onFavorite,
  favorite,
  hideFavouriteText,
}: SubheaderProps): JSX.Element => {
  const [parent, setParent] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setParent(document.getElementById(SUBHEADER_ID));
  }, []);
  if (!parent) return <></>;

  const children = (
    <Flex.Row className={styles.box}>
      {title && (
        <Flex.Row flex={1} align="center" fixedWidth className={styles.title}>
          {title}
        </Flex.Row>
      )}
      {!title && <Flex.Item flex={1} />}
      {!hideFavourite && (
        <FavouriteButton
          onClick={onFavorite}
          favorite={favorite}
          hideFavouriteText={hideFavouriteText}
        />
      )}
    </Flex.Row>
  );
  return <>{createPortal(children, parent)}</>;
};
