import {
  Collapse as AntCollapse,
  CollapseProps as AntCollapseProps,
} from "antd";
import classNames from "classnames";
import { ReactNode } from "react";
import { useTheme } from "../ui/Theme";
import styles from "./Collapse.module.scss";

const { Panel } = AntCollapse;

export interface CollapseProps extends AntCollapseProps {
  panelChildren?: ReactNode;
  panelTitle?: string;
  activeByDefault?: boolean;
}

export const Collapse = ({
  className,
  panelChildren,
  panelTitle: panelHeader,
  activeByDefault,
  ...props
}: CollapseProps): JSX.Element => {
  const [isDarkMode] = useTheme();
  return (
    <AntCollapse
      className={classNames(
        styles.collapse,
        { [styles.dark]: isDarkMode },
        className
      )}
      defaultActiveKey={activeByDefault ? ["collapse"] : []}
      ghost
      {...props}
    >
      <Panel header={panelHeader} key="collapse">
        {panelChildren}
      </Panel>
    </AntCollapse>
  );
};
