import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import googleSlice from "./googleSlice";
import lawsSlice from "./lawsSlice";
import historySlice from "./historySlice";
import fetchSlice from "./fetchSlice";

const rootReducer = combineReducers({
  laws: lawsSlice,
  google: googleSlice,
  history: historySlice,
  fetch: fetchSlice,
});
const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof rootReducer>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
