import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { syncGoogle } from "../googleAPI/syncGoogle";
import { GoogleTreeItem } from "../googleAPI/types";
import { TreeItemExtended } from "../utils/buildTree";

export const fetchGoogle = createAsyncThunk("google/fechGoogle", syncGoogle);

export type googleState = {
  driveTree: TreeItemExtended<GoogleTreeItem>[];
};

const initialState: googleState = {
  driveTree: [],
};

export const googleSlice = createSlice({
  name: "google",
  initialState,
  reducers: {
    setDriveTree: (state, action) => {
      state.driveTree = action.payload;
    },
  },
});

export const { setDriveTree } = googleSlice.actions;

export default googleSlice.reducer;
