import { RightOutlined, StarFilled, StarOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import classNames from "classnames";
import { useTheme } from "../ui/Theme";
import styles from "./Law.module.scss";

export interface LawProps {
  className?: string;
  favorite?: boolean;
  title?: string;
  subtitle?: string;
  onFavorite?: (isFavorite: boolean) => void;
  onClick?: () => void;
  hideFavourite?: boolean;
}
export interface FavouriteButtonProps {
  hideFavouriteText?: boolean;
  className?: string;
  favorite?: boolean;
  onClick?: (isFavorite: boolean) => void;
}

export const FavouriteButton = ({
  className,
  favorite,
  onClick,
  hideFavouriteText,
}: FavouriteButtonProps): JSX.Element => {
  const [isDarkMode] = useTheme();

  return (
    <Button
      type="text"
      onClick={(e) => {
        e.preventDefault();
        onClick?.(!favorite);
      }}
    >
      {!hideFavouriteText && (
        <Typography.Text
          type="secondary"
          style={{
            marginRight: 4,
          }}
        >
          Додати в обране
        </Typography.Text>
      )}
      <>
        {!favorite && (
          <StarOutlined
            className={classNames(
              styles.favorite,
              { [styles.dark]: isDarkMode },
              className
            )}
          />
        )}
        {favorite && (
          <StarFilled
            className={classNames(
              styles.favoriteActive,
              { [styles.dark]: isDarkMode },
              className
            )}
          />
        )}
      </>
    </Button>
  );
};

export const Law = ({
  className,
  title,
  subtitle,
  favorite,
  onFavorite,
  onClick,
  hideFavourite,
}: LawProps): JSX.Element => {
  const [isDarkMode] = useTheme();

  return (
    <div
      className={classNames(
        styles.box,
        { [styles.dark]: isDarkMode },
        className
      )}
      onClick={onClick}
    >
      {!hideFavourite && (
        <FavouriteButton
          favorite={favorite}
          onClick={onFavorite}
          hideFavouriteText
        />
      )}
      <div className={styles.textBox}>
        <Typography.Title className={styles.title} level={5}>
          {title}
        </Typography.Title>
        <Typography.Text type="secondary">{subtitle}</Typography.Text>
      </div>
      <RightOutlined className={styles.right} />
    </div>
  );
};
