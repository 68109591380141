import { WarningOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import classNames from "classnames";
import Flex from "../utils/Flex";
import styles from "./LoadingErrorAlert.module.scss";
import { useTheme } from "./Theme";

export type LoadingErrorAlertProps = {
  visible?: boolean;
};

export const LoadingErrorAlert = ({
  visible,
}: LoadingErrorAlertProps): JSX.Element => {
  const [isDark] = useTheme();
  return (
    <Flex
      gap={8}
      align="center"
      className={classNames(styles.alert, {
        [styles.visible]: visible,
        [styles.dark]: isDark,
      })}
    >
      <WarningOutlined />
      <Typography.Text>Дані застаріли й потребують оновлення!</Typography.Text>
    </Flex>
  );
};
