import { SyncOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { fetchGoogle } from "../app/googleSlice";
import { fetchLaws } from "../app/lawsSlice";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import { useDispatch } from "react-redux";
import Flex from "../utils/Flex";
import styles from "./ErrorScreen.module.scss";
import { useTheme } from "./Theme";

export type ErrorScreenProps = HTMLAttributes<HTMLDivElement> & {
  onSync?: () => void;
};

export const ErrorScreen = ({
  className,
  onSync,
  ...props
}: ErrorScreenProps): JSX.Element => {
  const [isDarkMode] = useTheme();
  return (
    <Flex
      col
      fullHeight
      fullWidth
      align="center"
      justify="center"
      gap={8}
      className={classNames(
        styles.errorScreen,
        {
          [styles.dark]: isDarkMode,
        },
        className
      )}
      {...props}
    >
      <div>Щось пішло не так, спробуйте знову</div>
      <Button
        className={styles.refreshButton}
        onClick={() => {
          localStorage.clear();
          window.location.replace("/docs");
          window.location.reload();
        }}
      >
        <SyncOutlined />
      </Button>
    </Flex>
  );
};
