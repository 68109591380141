import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child, set } from "firebase/database";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCFlZ0XSMfhQJDpmSmbDTw0YGL4RChSAM4",
  authDomain: "police-and-election.firebaseapp.com",
  databaseURL:
    "https://police-and-election-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "police-and-election",
  storageBucket: "police-and-election.appspot.com",
  messagingSenderId: "486164331388",
  appId: "1:486164331388:web:e197cdc0ac125ccd5dc2f9",
  measurementId: "G-WMWJESKPY8",
};

const app = initializeApp(firebaseConfig);

export const syncStatisticsData = async () => {
  try {
    const data = JSON.parse(localStorage.getItem("links") || "{}");
    const auth = getAuth();
    await signInWithEmailAndPassword(
      auth,
      "policiatavyborymobileapp@opora.org.ua",
      "123456"
    );
    const dbRef = ref(getDatabase(app));
    const fireBaseData: any[] = await get(child(dbRef, `laws`)).then(
      (snapshot) => (snapshot.exists() ? snapshot.val() : {})
    );

    const fireBaseDataToMap = Array.isArray(fireBaseData) ? fireBaseData : [];

    const firebaseMap = Object.fromEntries(
      fireBaseDataToMap.map(({ id, name, count }) => [
        JSON.stringify([name, id]),
        count,
      ])
    );

    const newData = { ...(firebaseMap ?? {}) };
    for (const key in data) {
      // @ts-ignore
      newData[key] = (newData[key] ?? 0) + data[key];
    }

    const result = Object.entries(newData).map(([key, count]) => {
      const [name, id] = JSON.parse(key);
      return { id, name, count };
    });

    await set(ref(getDatabase(app), "laws"), result);
  } catch (error) {
    console.log("error", error);
  }
};
