import { concatUrl } from "../utils/concatUrl";
import { GoogleTreeItem } from "./types";

export const downloadFolder = async (
  folderId: string
): Promise<GoogleTreeItem[]> => {
  const origin = process.env.REACT_APP_FIREBASE_DRIVE;
  const response = await fetch(
    // @ts-ignore
    concatUrl(origin, `/api/folder/${folderId}`)
  ).then((response) => response.json());
  const { data } = response;
  return data;
};
