import { SyncOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNetworkState, useUpdateEffect } from "react-use";
import { useRefresh } from "../hooks/useRefresh";
import { Moon } from "../icons/Moon";
import { Sun } from "../icons/Sun";
import { ConfirmUpdateModal } from "../ui/ConfirmUpdateModal";
import { LoadErrorModal } from "../ui/LoadErrorModal";
import { useTheme } from "../ui/Theme";
import Flex from "../utils/Flex";

const buttonStyle = {
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "100px",
};

export type SettingsProps = {};

export const Settings = ({}: SettingsProps): JSX.Element => {
  const { online } = useNetworkState();
  const [isDarkMode, setIsDarkMode] = useTheme();
  const [modalVisible, setModalVisible] = useState("");
  const [{ showErrorModal }, { refresh, setErrorModal }] = useRefresh();

  return (
    <Flex.Col gap={16} fullHeight>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Налаштування
      </Typography.Title>
      <Flex.Row align="center" gap={16}>
        <Button
          style={buttonStyle}
          onClick={() => {
            setIsDarkMode(!isDarkMode);
            localStorage.setItem("theme", isDarkMode ? "white" : "dark");
          }}
        >
          {isDarkMode ? <Sun /> : <Moon />}
        </Button>
        <Typography.Title level={5} style={{ margin: 0 }}>
          Змінити тему
        </Typography.Title>
      </Flex.Row>
      {online && (
        <Flex.Row align="center" gap={16}>
          <Button
            type="primary"
            style={buttonStyle}
            onClick={() => {
              setModalVisible("confirmUpdate");
            }}
          >
            <SyncOutlined />
          </Button>
          <Typography.Title level={5} style={{ margin: 0 }}>
            Оновити Дані
          </Typography.Title>
        </Flex.Row>
      )}
      <ConfirmUpdateModal
        visible={modalVisible === "confirmUpdate"}
        onCancel={() => setModalVisible("")}
        onOk={() => {
          setModalVisible("");
          refresh();
        }}
      />
      <LoadErrorModal
        visible={showErrorModal}
        onCancel={() => setErrorModal(false)}
        onOk={() => setErrorModal(false)}
      />
    </Flex.Col>
  );
};
