export type TreeNode<T> = {
  children: TreeNode<T>[];
} & T;

export type TreeItem = {
  id: string;
  parentId?: string;
  hasChildren?: boolean;
  isInitial?: boolean;
  isSelected?: boolean;
};

export type TreeItemExtended<T extends TreeItem> = TreeNode<{
  key: string;
  title: string;
  value: T;
  className?: string;
  isLeaf?: boolean;
}>;

export type TreeIndex<T extends TreeItem> = Record<string, TreeItemExtended<T>>;

export const buildTree = <T extends TreeItem>(
  data: T[],
  topLevelClassName?: string
): [TreeItemExtended<T>[], Record<string, TreeItemExtended<T>>] => {
  const index: TreeIndex<T> = Object.fromEntries(
    data
      .map((treeItem) => [
        treeItem.id,
        {
          key: treeItem.id,
          title: `treeItem-${treeItem.id}`,
          children: [],
          isLeaf: !treeItem.hasChildren,
          value: treeItem,
          className: treeItem.parentId === null ? topLevelClassName : undefined,
        },
      ])
      .sort(
        (
          [
            ,
            {
              // @ts-ignore
              value: { name: a = "" },
            },
          ],
          [
            ,
            {
              // @ts-ignore
              value: { name: b = "" },
            },
          ]
        ) => {
          const matchA = a.match(new RegExp("[0-9]*_"));
          const matchB = b.match(new RegExp("[0-9]*_"));
          if (!!matchA && !matchB) return -1;
          if (!matchA && !!matchB) return 1;
          if (!!matchA && !!matchB) {
            const [textA] = matchA;
            const [textB] = matchB;
            const textAMapped = Number(textA.slice(0, textA.length - 1)) || 0;
            const textBMapped = Number(textB.slice(0, textB.length - 1)) || 0;
            return textAMapped - textBMapped;
          }
          return a.localeCompare(b);
        }
      )
  );
  for (const treeItem of Object.values(index)) {
    if (treeItem.value.parentId === null) continue;
    const parent = index[treeItem.value.parentId || ""];
    if (!parent) continue;
    parent.isLeaf = false;
    parent.children.push(treeItem);
  }
  const tree = Object.values(index).filter(
    ({ value: { parentId } }) => !parentId
  );

  return [tree, index];
};
