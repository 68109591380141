import { DOCS_DRIVE_FOLDER } from "../App";
import DocView from "../components/DocView";
import FoldersView from "../components/FoldersView";
import { getDoc } from "../googleAPI/getDoc";
import { getDriveItem } from "../googleAPI/getDriveItem";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { useTheme } from "../ui/Theme";
import Flex from "../utils/Flex";

export const Doc = () => {
  const [isDark] = useTheme();
  const { id } = useParams();
  const { value: item, loading } = useAsync(async () => getDoc(id), [id]);
  if (!item && !loading)
    return (
      <Flex
        fullHeight
        fullWidth
        justify="center"
        align="center"
        style={{ color: isDark ? "#fff" : undefined }}
      >
        Не знайдено
      </Flex>
    );
  if (!item) return <></>;
  return <DocView {...item} />;
};
