import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { replace } from "../app/historySlice";
import { AppState } from "../app/store";
import { routes } from "../routes";

export const useHistory = () => {
  const historyList = useSelector((state: AppState) => state.history.history);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const goBack = () => {
    if (historyList.length <= 1) return;
    const lastPath = historyList.at(-1)?.pathname as string;
    for (let i = historyList.length - 1; i !== -1; i--) {
      const currentPath = historyList[i].pathname;
      if (currentPath !== lastPath) {
        const backFromSearch =
          (currentPath.startsWith(routes.folders) ||
            currentPath.startsWith(routes.favourites) ||
            currentPath.startsWith(routes.laws)) &&
          !!historyList[i].search;
        dispatch(
          replace(
            historyList.filter((_, index) =>
              backFromSearch ? index < i - 1 : index < i
            )
          )
        );
        const fullPath = `${historyList[i].pathname}${
          backFromSearch ? "" : historyList[i].search
        }`;
        navigate(fullPath);
        break;
      }
    }
  };

  return {
    canGoBack: historyList.length > 1,
    goBack,
  };
};

export const useTrackHistory = () => {
  const historyList = useSelector((state: AppState) => state.history.history);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    dispatch(replace([...historyList, location]));
  }, [location]);
};
