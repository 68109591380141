import { Drawer as AntDrawer, DrawerProps } from "antd";
import Flex, { FlexRowColProps } from "../utils/Flex";
import styles from "./Drawer.module.scss";

export const Drawer = ({
  style,
  open,
  onClose,
  ...props
}: FlexRowColProps & DrawerProps) => {
  return (
    <AntDrawer
      placement="left"
      className={styles.drawer}
      closable={false}
      {...{ open, onClose }}
    >
      <Flex.Col
        gap={16}
        fullHeight
        style={{ padding: 32, ...style }}
        {...props}
      />
    </AntDrawer>
  );
};
