import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const Moon = (props: Partial<CustomIconComponentProps>): JSX.Element => (
  <Icon
    component={() => (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_340_42406)">
          <path
            d="M7.74402 14C9.90314 14 11.8751 13.0177 13.1815 11.408C13.3748 11.1699 13.164 10.8221 12.8654 10.8789C9.46919 11.5257 6.35039 8.92177 6.35039 5.49347C6.35039 3.51865 7.40755 1.70267 9.12573 0.724883C9.39058 0.574164 9.32397 0.172621 9.02302 0.117031C8.60113 0.0392364 8.17302 6.38842e-05 7.74402 0C3.8801 0 0.744019 3.13116 0.744019 7C0.744019 10.8639 3.87518 14 7.74402 14Z"
            fill="#060C13"
            fillOpacity="0.65"
          />
        </g>
        <defs>
          <clipPath id="clip0_340_42406">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
