import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const FolderFallback = (
  props: Partial<CustomIconComponentProps>
): JSX.Element => (
  <Icon
    component={() => (
      <svg
        width="40"
        height="40"
        viewBox="0 0 32 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 25L15.7749 7.03633L25.9143 25H6Z"
          fill="#3972AC"
        />
      </svg>
    )}
    {...props}
  />
);
