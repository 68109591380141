import { Card } from "antd";
import { Link } from "react-router-dom";
import { stringZip } from "../utils/stringZip";
import styles from "./Panel.module.scss";

const MAX_CHARACTERS = 50;

export interface PanelProps {
  title: string;
  icon?: React.ReactNode;
  link: string;
  zipTitle?: boolean;
  onClick?: () => void;
}

export const Panel = ({
  title,
  icon,
  link,
  zipTitle,
  onClick,
}: PanelProps): JSX.Element => {
  return (
    <Link to={link} onClick={onClick}>
      <Card className={styles.card}>
        <div className={styles.box}>
          {icon && <div className={styles.iconWrapper}>{icon}</div>}
          <div className={styles.title}>
            {zipTitle ? stringZip(title, MAX_CHARACTERS) : title}
          </div>
        </div>
      </Card>
    </Link>
  );
};
