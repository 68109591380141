import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import Flex from "../utils/Flex";
import styles from "./SearchControls.module.scss";
import { useTheme } from "./Theme";

export const SearchControls = ({
  onNext,
  onPrev,
  disableUp,
  disableDown,
}: {
  onNext?: () => void;
  onPrev?: () => void;
  disableUp?: boolean;
  disableDown?: boolean;
}) => {
  const [isDarkMode] = useTheme();

  return (
    <Flex.Row className={classNames(styles.box, { [styles.dark]: isDarkMode })}>
      <Button
        type="text"
        className={styles.themeButton}
        onClick={onNext}
        disabled={disableDown}
      >
        <DownOutlined />
      </Button>
      <Button
        type="text"
        className={styles.themeButton}
        onClick={onPrev}
        disabled={disableUp}
      >
        <UpOutlined />
      </Button>
    </Flex.Row>
  );
};
