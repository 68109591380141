const CHACTERS_TO_ESCAPE = new Set([
  ".",
  "^",
  "$",
  "*",
  "+",
  "-",
  "?",
  "(",
  ")",
  "[",
  "]",
  "{",
  "}",
  "\\",
  "|",
  "—",
  "/",
]);

export const escapeRegExpString = (s: string): string => {
  let newS = "";
  for (let i = 0; i < s.length; i++) {
    const char = s.charAt(i);
    newS += CHACTERS_TO_ESCAPE.has(char) ? `\\${char}` : char;
  }
  return newS;
};
