import { generatePath, ParamParseKey } from "react-router-dom";

export type RouteParams<T extends keyof typeof routes> = {
  [key in ParamParseKey<(typeof routes)[T]>]: string | null;
};

export const routes = {
  laws: "/article",
  law: "/article/:id",
  favourites: "/favourites",
  doc: "/doc/:id",
  docBase: "/doc",
  folders: "/folder",
  folder: "/folder/:id",
  uiKit: "/uiKit",
  about: "/about",
  library: "/library",
  search: "/search",
  settings: "/settings",
} as const satisfies Record<string, string>;

export const resolveRoute = <T extends keyof typeof routes>(
  route: T,
  params: RouteParams<T>
): string => generatePath(routes[route], params);
