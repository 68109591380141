const STRATEGIES: Record<
  string,
  (s: string, lengthOverflow: number) => string
> = {
  end: (s, lengthOverflow) => `${s.slice(0, lengthOverflow)}...`,
  middle: (s, lengthOverflow) =>
    `${s.slice(0, lengthOverflow / 2)}...${s.slice((lengthOverflow / 2) * -1)}`,
};

export const stringZip = (
  s: string,
  lengthOverflow = 25,
  strategy: "end" | "middle" = "end"
): string => {
  if (s.length < lengthOverflow) {
    return s;
  }
  return STRATEGIES[strategy](s, lengthOverflow);
};
