import { Link } from "react-router-dom";
import { Logo } from "../icons/Logo";
import { LogoDark } from "../icons/LogoDark";
import { routes } from "../routes";
import { useTheme } from "../ui/Theme";
import styles from "./Header.module.scss";
import { useHeaderValue } from "./HeaderContext";
import { IconWrapper } from "./IconWrapper";
import { SearchBar, SearchBarProps } from "./SearchBar";

export interface HeaderProps extends SearchBarProps {
  onLogoClick?: () => void;
}

export const Header = ({ onLogoClick, ...props }: HeaderProps): JSX.Element => {
  const [isDarkMode] = useTheme();
  const [value, setValue] = useHeaderValue();

  return (
    <div className={styles.box} id="header">
      <SearchBar
        {...props}
        value={value || ""}
        onChange={(e) => setValue(e.target.value)}
      />
      <Link to={routes.folders}>
        {isDarkMode && (
          <IconWrapper onClick={onLogoClick} size={6} pointer>
            <LogoDark />
          </IconWrapper>
        )}
        {!isDarkMode && (
          <IconWrapper onClick={onLogoClick} size={6} pointer>
            <Logo />
          </IconWrapper>
        )}
      </Link>
    </div>
  );
};
