import React, { ReactNode } from "react";
import { useSearchParams } from "react-router-dom";

const HeaderContext = React.createContext<{
  value: string | undefined;
  setValue: (value: string | undefined) => void;
}>({ value: "", setValue: () => null });

export const useHeaderValue = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  return [
    searchParams.get("search"),
    (search: string) => setSearchParams({ search }),
  ] as const;
};

export const HeaderContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [value, setValue] = React.useState<string | undefined>("");

  return (
    <HeaderContext.Provider value={{ value, setValue }}>
      {children}
    </HeaderContext.Provider>
  );
};
