import { format } from "date-fns";
import { uk } from "date-fns/locale";
import { Database } from "../db/db";

export type Doc = {
  id: string;
  markdown: string;
  title: string;
  tags: string[];
  subTitle: string;
};

export const getDoc = async (id = ""): Promise<Doc | null> => {
  const db = Database.getInstance();

  const markdown: any = await db.getById("doc", id);

  if (!markdown) return null;
  const tagsContentSeparator = "\r\n";
  const tagsSeparator = ",";
  const [tagsLine, ...markdownLines] = markdown.doc.split(tagsContentSeparator);
  const prefix = "tags: ";
  const title = format(new Date(markdown.createdTime), "HH:mm, d MMMM y", {
    locale: uk,
  });

  if (tagsLine.includes(prefix)) {
    return {
      id,
      markdown: markdownLines.join(tagsContentSeparator),
      title,
      subTitle: markdown.name,
      tags: tagsLine.slice(prefix.length).split(tagsSeparator),
    };
  }
  return {
    id,
    markdown: markdown.doc,
    title,
    subTitle: markdown.name,
    tags: [],
  };
};
