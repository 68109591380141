import { createSlice } from "@reduxjs/toolkit";
import { Location } from "react-router-dom";

export type HistoryState = {
  history: Location[];
};

const initialState: HistoryState = {
  history: [],
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {
    replace: (state, action) => {
      state.history = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { replace } = historySlice.actions;

export default historySlice.reducer;
