import { concatUrl } from "../utils/concatUrl";

export const downloadSheet = async (id: string): Promise<string[]> => {
  // const folderData = getDocFromStorage(fileId);
  // if (folderData && folderData.doc) return folderData.doc;

  // const file = await gapi.client.drive.files.get({
  //   fileId,
  // });
  // if (file.result.mimeType.startsWith("text")) {
  //   const response = await gapi.client.drive.files.get({
  //     fileId,
  //     alt: "media",
  //   });
  //   return response.body;
  // }

  // const response = await gapi.client.drive.files.export({
  //   fileId,
  //   mimeType: "text/plain",
  // });
  const origin = process.env.REACT_APP_FIREBASE_DRIVE;
  // @ts-ignore
  const response = await fetch(concatUrl(origin, `/api/sheet/${id}`)).then(
    (response) => response.json()
  );
  return response.data;
};
