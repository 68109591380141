import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { replace, setErrorModal as _setErrorModal } from "../app/fetchSlice";
import { AppState } from "../app/store";
import { Database } from "../db/db";
import { syncStatisticsData } from "../firebase/realtimeDatabase";
import { initGAPI } from "../googleAPI/initGAPI";
import { syncGoogle } from "../googleAPI/syncGoogle";
import { syncLaws } from "../lawsAPI/syncLaws";

export const useRefresh = (): any => {
  const state = useSelector((state: AppState) => state.fetch.state);
  const showErrorModal = useSelector(
    (state: AppState) => state.fetch.showErrorModal
  );
  const dispatch = useDispatch();

  const setState = useCallback(
    (state: string) => {
      dispatch(replace(state));
    },
    [dispatch]
  );

  const setErrorModal = useCallback(
    (state: boolean) => {
      dispatch(_setErrorModal(state));
    },
    [dispatch]
  );

  const refresh = async () => {
    setState("pending");
    await initGAPI();
    syncStatisticsData();
    const db = Database.getInstance();
    const theme = localStorage.getItem("theme");
    const favoriteLaws = localStorage.getItem("favoriteLaws");
    const driveTree = localStorage.getItem("driveTree");
    const lawsIds = localStorage.getItem("lawsIds");
    const typeMap = localStorage.getItem("typeMap");
    localStorage.clear();
    await db.checkIntegrity();
    await db.backup();
    await db.clearAll();
    if (theme) localStorage.setItem("theme", theme);
    if (favoriteLaws) localStorage.setItem("favoriteLaws", favoriteLaws);
    try {
      await Promise.all([syncGoogle(), syncLaws()]);
      localStorage.setItem("loaded", `${Date.now()}`);
      setState("success");
      localStorage.setItem("errorLoading", "false");
      return false;
    } catch (error) {
      console.log("error", error);
      await db.restore();
      localStorage.setItem("loaded", `${Date.now()}`);
      if (driveTree) localStorage.setItem("driveTree", driveTree);
      if (lawsIds) localStorage.setItem("lawsIds", lawsIds);
      if (typeMap) localStorage.setItem("typeMap", typeMap);
      localStorage.setItem("errorLoading", "true");
      setState("error");
      setErrorModal(true);
      return true;
    }
  };

  return [
    { state, showErrorModal },
    { refresh, setErrorModal },
  ];
};
