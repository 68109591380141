import { DOCS_DRIVE_FOLDER } from "../App";
import FoldersView from "../components/FoldersView";
import { getFolder } from "../googleAPI/getFolder";
import { useSearch } from "../hooks/useSearch";
import { useParams } from "react-router-dom";
import { useAsync } from "react-use";
import { routes } from "../routes";
import { useTheme } from "../ui/Theme";
import Flex from "../utils/Flex";

export const Folders = () => {
  const [isDark] = useTheme();
  const { id = DOCS_DRIVE_FOLDER } = useParams();
  const { value: item, loading } = useAsync(async () => {
    return getFolder(id);
  }, [id]);

  useSearch(routes.folders);

  if (!item && !loading)
    return (
      <Flex
        fullHeight
        fullWidth
        justify="center"
        align="center"
        style={{ color: isDark ? "#fff" : undefined }}
      >
        Не знайдено
      </Flex>
    );
  if (!item) return <></>;
  return <FoldersView parentId={id} folders={item} />;
};
