import { MenuOutlined } from "@ant-design/icons";
import { Card, Input, InputProps } from "antd";
import classNames from "classnames";
import { useTheme } from "../ui/Theme";
import Flex from "../utils/Flex";
import styles from "./SearchBar.module.scss";

export interface SearchBarProps extends InputProps {
  onBurgerClick?: () => void;
}

export const SearchBar = ({
  onBurgerClick,
  className,
  placeholder,
  ...props
}: SearchBarProps): JSX.Element => {
  const [isDarkMode] = useTheme();
  return (
    <Card className={styles.card}>
      <Flex.Row className={styles.row} fullWidth>
        <MenuOutlined
          className={classNames(styles.icon, { [styles.dark]: isDarkMode })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onBurgerClick?.();
          }}
        />
        <Input
          size="large"
          placeholder={placeholder || "Пошук"}
          className={classNames(styles.input, className)}
          {...props}
        />
      </Flex.Row>
    </Card>
  );
};
