import { concatUrl } from "../utils/concatUrl";

export const downloadImage = async (id: string): Promise<string> => {
  const origin = process.env.REACT_APP_FIREBASE_DRIVE;
  // @ts-ignore
  const response = await fetch(concatUrl(origin, `/api/image/${id}`)).then(
    (response) => response.json()
  );
  return response.data;
};
