import { removeFavoriteLaw } from "../app/lawsSlice";
import { AppState } from "../app/store";
import { Database } from "../db/db";
import { useSearch } from "../hooks/useSearch";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAsync } from "react-use";
import { Virtuoso } from "react-virtuoso";
import { resolveRoute, routes } from "../routes";
import { ConfirmUnfavorite } from "../ui/ConfirmUnfavorite";
import { useHeaderValue } from "../ui/HeaderContext";
import { Law as LawItem } from "../ui/Law";
import { useTheme } from "../ui/Theme";
import { escapeRegExpString } from "../utils/escapeRegExpString";
import { Typography } from "antd";
import Flex from "../utils/Flex";

// TODO: use favorites state and resolve correct link for it
export const Favourites = () => {
  const [isDarkMode] = useTheme();
  const [value = ""] = useHeaderValue();
  const favorites = useSelector((state: AppState) => state.laws.favoriteLaws);
  const dispatch = useDispatch();
  const [visible, setVisible] = useState("");

  useSearch(routes.favourites);

  const { value: favoriteItems } = useAsync(async () => {
    const db = Database.getInstance();
    return (
      await Promise.all(
        // @ts-ignore
        favorites.map(async (id) => {
          const doc = await db.getById("doc", id);
          if (doc) return { type: "doc", ...doc };
          const law = await db.getById("law", id);
          if (law) return law;
          return null;
        })
      )
    ).filter(Boolean);
  }, [favorites]);

  const filteredLaws = ((favoriteItems || []) as any[])
    .filter(({ name = "" }) =>
      name.match(new RegExp(escapeRegExpString(value || ""), "gi"))
    )
    .sort(({ name: a = "" }, { name: b = "" }) => a.localeCompare(b));

  return (
    <>
      <ConfirmUnfavorite
        visible={visible !== ""}
        onOk={() => {
          dispatch(removeFavoriteLaw(visible));
          setVisible("");
        }}
        onCancel={() => setVisible("")}
      />
      {filteredLaws.length === 0 && (
        <Flex.Col fullHeight align="center" justify="center">
          <Typography.Text>У вас поки немає обраних матеріалів</Typography.Text>
        </Flex.Col>
      )}
      {filteredLaws.length !== 0 && (
        <Virtuoso
          style={{ height: "100%" }}
          data={filteredLaws}
          itemContent={(index, { id, name, type = "law" }) => (
            <Fragment key={id}>
              <Link to={resolveRoute(type, { id })}>
                <LawItem
                  title={name}
                  favorite={true}
                  onFavorite={() => {
                    setVisible(id);
                  }}
                />
              </Link>
              <div
                style={{
                  height: 1,
                  width: "100%",
                  background: isDarkMode ? "#1D3B5D" : "#D9D9D9",
                }}
              />
            </Fragment>
          )}
        />
      )}
    </>
  );
};
