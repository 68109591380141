import store from "./app/store";
import * as React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ErrorScreen } from "./ui/ErrorScreen";
import { HeaderContextProvider } from "./ui/HeaderContext";
import { ThemeContextProvider } from "./ui/Theme";
import ErrorBoundary from "./utils/ErrorBoundary";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <ThemeContextProvider>
      <BrowserRouter>
        <HeaderContextProvider>
          <ErrorBoundary component={<ErrorScreen />}>
            <App />
          </ErrorBoundary>
        </HeaderContextProvider>
      </BrowserRouter>
    </ThemeContextProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
