import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const Sun = (props: Partial<CustomIconComponentProps>): JSX.Element => (
  <Icon
    component={() => (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_340_42404)">
          <path
            d="M7 4.375C5.55352 4.375 4.375 5.55352 4.375 7C4.375 8.44648 5.55352 9.625 7 9.625C8.44648 9.625 9.625 8.44648 9.625 7C9.625 5.55352 8.44648 4.375 7 4.375ZM13.7375 6.57617L11.148 5.28281L12.0641 2.5375C12.1871 2.16563 11.8344 1.81289 11.4652 1.93867L8.71992 2.85469L7.42383 0.2625C7.24883 -0.0875 6.75117 -0.0875 6.57617 0.2625L5.28281 2.85195L2.53477 1.93594C2.16289 1.81289 1.81016 2.16562 1.93594 2.53477L2.85195 5.28008L0.2625 6.57617C-0.0875 6.75117 -0.0875 7.24883 0.2625 7.42383L2.85195 8.71719L1.93594 11.4652C1.81289 11.8371 2.16562 12.1898 2.53477 12.0641L5.28008 11.148L6.57344 13.7375C6.74844 14.0875 7.24609 14.0875 7.42109 13.7375L8.71445 11.148L11.4598 12.0641C11.8316 12.1871 12.1844 11.8344 12.0586 11.4652L11.1426 8.71992L13.732 7.42656C14.0875 7.24883 14.0875 6.75117 13.7375 6.57617ZM9.47461 9.47461C8.11016 10.8391 5.88984 10.8391 4.52539 9.47461C3.16094 8.11016 3.16094 5.88984 4.52539 4.52539C5.88984 3.16094 8.11016 3.16094 9.47461 4.52539C10.8391 5.88984 10.8391 8.11016 9.47461 9.47461Z"
            fill="white"
            fillOpacity="0.65"
          />
        </g>
        <defs>
          <clipPath id="clip0_340_42404">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);
