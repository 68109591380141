export type Node = {
  tag: string;
  children: (string | Node)[];
  parentNode?: Node;
};

const singleElements = [
  "img",
  "br",
  "hr",
  "!doctype",
  "area",
  "base",
  "col",
  "embed",
  "input",
  "keygen",
  "link",
  "meta",
  "param",
  "source",
  "track",
  "wbr",
];

export const parseHTML = (html: string) => {
  let index = 0;
  const end = html.length;
  let tree;
  let currentNode: Node | undefined;
  let tag = "";
  let text = "";
  while (index !== end) {
    const char = html[index];
    const nextChar = html[index + 1];
    if (char === "<") {
      if (text !== "" && currentNode) {
        currentNode.children.push(text);
        text = "";
      }
      if (nextChar.match(/[a-zA-Z0-9]/)) {
        const slice = html.slice(index);
        const closeIndex = slice.indexOf(">");
        const preClose = slice[closeIndex - 1];
        tag = html.slice(
          index + 1,
          preClose === "/" ? index + closeIndex - 1 : index + closeIndex
        );
        const newNode: Node = { tag, children: [], parentNode: currentNode };
        if (!tree) {
          tree = newNode;
          currentNode = newNode;
        } else {
          currentNode?.children.push(newNode);
        }
        if (
          preClose !== "/" &&
          !singleElements.some((el) => tag.startsWith(el))
        ) {
          currentNode = newNode;
        }
        index += closeIndex + 1;
        continue;
      } else if (
        nextChar === "/" &&
        currentNode?.parentNode &&
        html.slice(index + 2).startsWith(currentNode.tag.split(" ")[0])
      ) {
        const slice = html.slice(index + 1);
        const closeIndex = slice.indexOf(">");
        if (currentNode?.parentNode) currentNode = currentNode.parentNode;
        index += closeIndex + 2;
        continue;
      }
    }
    text += char;
    index++;
  }
  return tree;
};

export const stringifyHTML = (node: Node | string): string => {
  if (typeof node === "string") return node;
  const { tag, children } = node;
  const [closeTag] = tag.split(" ");
  return `<${tag}>${children.map(stringifyHTML).join("")}</${closeTag}>`;
};

export const mapHTMLTree = (
  node: Node | string,
  mapper: (node: Node | string) => Node | string
): Node | string => {
  if (typeof node === "string") return mapper(node);
  return {
    ...node,
    children: node.children.map((entry) => mapHTMLTree(entry, mapper)),
  };
};
