import { addFavoriteLaw, removeFavoriteLaw } from "../app/lawsSlice";
import { AppState } from "../app/store";
import { Database } from "../db/db";
import { useSearch } from "../hooks/useSearch";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { useAsync } from "react-use";
import { Virtuoso } from "react-virtuoso";
import { resolveRoute, routes } from "../routes";
import { ConfirmUnfavorite } from "../ui/ConfirmUnfavorite";
import { useHeaderValue } from "../ui/HeaderContext";
import { Law as LawItem } from "../ui/Law";
import { useTheme } from "../ui/Theme";
import { escapeRegExpString } from "../utils//escapeRegExpString";

export const Laws = () => {
  const [isDarkMode] = useTheme();
  const [value = ""] = useHeaderValue();
  const [visible, setVisible] = useState("");
  const favorites = useSelector((state: AppState) => state.laws.favoriteLaws);
  const dispatch = useDispatch();

  useSearch(routes.laws);

  const { value: laws } = useAsync(async () => {
    const db = Database.getInstance();
    return db.getAll("law");
  }, []);

  const filteredLaws = ((laws as any[]) || [])
    .filter(({ name = "" }) =>
      name.match(new RegExp(escapeRegExpString(value || ""), "gi"))
    )
    .sort(({ name: a = "" }, { name: b = "" }) => a.localeCompare(b));

  return (
    <>
      <ConfirmUnfavorite
        visible={visible !== ""}
        onOk={() => {
          dispatch(removeFavoriteLaw(visible));
          setVisible("");
        }}
        onCancel={() => setVisible("")}
      />
      <Virtuoso
        style={{ height: "100%" }}
        data={filteredLaws}
        itemContent={(index, { id, name }) => (
          <Fragment key={id}>
            <Link to={resolveRoute("law", { id })} key={id}>
              <LawItem
                title={name ?? id}
                favorite={favorites.includes(id)}
                onFavorite={() => {
                  if (favorites.includes(id)) {
                    setVisible(id);
                  } else {
                    dispatch(addFavoriteLaw(id));
                  }
                }}
              />
            </Link>
            <div
              style={{
                height: 1,
                width: "100%",
                background: isDarkMode ? "#1D3B5D" : "#D9D9D9",
              }}
            />
          </Fragment>
        )}
      />
    </>
  );
};
