import { SyncOutlined } from "@ant-design/icons";
import {
  createContext,
  Suspense as ReactSuspense,
  SuspenseProps,
  useContext,
} from "react";
import { useUpdate } from "react-use";
import Flex from "./Flex";

type SuspenseContext = { current: number; cache: Record<number, unknown> };
const CacheContext = createContext<SuspenseContext>({ current: 0, cache: {} });

export const Suspense = (props: SuspenseProps) => (
  <CacheContext.Provider value={{ current: 0, cache: {} }}>
    <ReactSuspense
      fallback={
        <Flex.Col align="center" justify="center" fullHeight fullWidth>
          <SyncOutlined spin style={{ color: "white" }} />
        </Flex.Col>
      }
      {...props}
    />
  </CacheContext.Provider>
);

export const useSuspense = <T,>(fn: () => Promise<T>) => {
  const context = useContext(CacheContext);
  const update = useUpdate();
  const i = context.current;
  ++context.current;
  const invalidate = () => {
    delete context.cache[i];
    update();
  };
  const save = (value: unknown) => {
    context.current = 0;
    context.cache[i] = value;
  };

  if (i in context.cache) return [context.cache[i] as T, invalidate] as const;

  throw fn().then(save);
};
