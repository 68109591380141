import { createSlice } from "@reduxjs/toolkit";

export type FetchState = {
  state: string;
  showErrorModal: boolean;
};

const initialState: FetchState = {
  state: localStorage.getItem("loaded") ? "success" : "uninitialized",
  showErrorModal: false,
};

export const fetchSlice = createSlice({
  name: "fetch",
  initialState,
  reducers: {
    replace: (state, action) => {
      state.state = action.payload;
    },
    setErrorModal: (state, action) => {
      state.showErrorModal = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { replace, setErrorModal } = fetchSlice.actions;

export default fetchSlice.reducer;
