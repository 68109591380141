import { SyncOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAsync } from "react-use";
import { Virtuoso } from "react-virtuoso";
import { Database } from "../db/db";
import { useHistory } from "../hooks/useHistory";
import { FolderFallback } from "../icons/FolderFallback";
import { resolveRoute } from "../routes";
import { useHeaderValue } from "../ui/HeaderContext";
import { Law } from "../ui/Law";
import { Subheader } from "../ui/Subheader";
import { useTheme } from "../ui/Theme";
import { getElSrcFromImageSrc } from "../utils";
import Flex from "../utils/Flex";

export type SearchProps = {};

export const Search = ({}: SearchProps): JSX.Element => {
  const [isDarkMode] = useTheme();
  const [value = ""] = useHeaderValue();
  const { goBack } = useHistory();

  useEffect(() => {
    if (value) return;
    goBack();
  }, [value]);

  const { value: foldersToRender = [], loading } = useAsync(async () => {
    if (!value) return [];
    const db = Database.getInstance();
    const laws = await db.getAll("law");
    const docs = await db.getAll("doc");

    const ids = [];

    if (value) {
      for (const { id, name, doc } of docs) {
        if (
          name.match(new RegExp(value || "", "gi")) ||
          doc.match(new RegExp(value || "", "gi"))
        )
          ids.push({ type: "doc", id });
      }
      for (const { id, text } of laws) {
        if (text.match(new RegExp(value || "", "gi")))
          ids.push({ type: "law", id });
      }
    }

    const entries = await Promise.all(
      ids.map(async ({ type, id }) => ({
        type,
        entry: await db.getById(type, id),
      }))
    );

    const data = await Promise.all(
      entries.map(async ({ type, entry }: any) => {
        const icon = entry.icon
          ? await db.getById("image", entry.icon.src)
          : undefined;
        return {
          ...entry,
          title: entry.name,
          icon,
          type,
        };
      })
    );

    const foldersToRender = data
      .sort(
        ({ title: a = "", type: aType }, { title: b = "", type: bType }) => {
          const matchA = a.match(new RegExp("[0-9]*_"));
          const matchB = b.match(new RegExp("[0-9]*_"));
          if (aType === "folder" && bType !== "folder") return -1;
          if (aType !== "folder" && bType === "folder") return 1;
          if (aType === "doc" && bType !== "doc") return -1;
          if (aType !== "doc" && bType === "doc") return 1;
          if (!!matchA && !matchB) return -1;
          if (!matchA && !!matchB) return 1;
          if (!!matchA && !!matchB) {
            const [textA] = matchA;
            const [textB] = matchB;
            const textAMapped = Number(textA.slice(0, textA.length - 1)) || 0;
            const textBMapped = Number(textB.slice(0, textB.length - 1)) || 0;
            return textAMapped - textBMapped;
          }
          return a.localeCompare(b);
        }
      )
      .map(({ icon, id, title, mimeType, type }) => {
        const match = title.match(new RegExp("[0-9]*_"));
        const _title = !match ? title : title.slice(match[0].length);
        const _icon =
          mimeType === "application/vnd.google-apps.folder" ? (
            icon ? (
              <img src={getElSrcFromImageSrc(icon.mimeType, icon.data)} />
            ) : (
              <FolderFallback />
            )
          ) : undefined;
        return {
          id,
          title: _title,
          icon: _icon,
          type,
        };
      });

    return foldersToRender;
  }, [value]);

  if (loading)
    return (
      <Flex fullHeight fullWidth align="center" justify="center" gap={16}>
        <Typography.Text
          style={{
            fontSize: 16,
          }}
        >
          <SyncOutlined spin />
        </Typography.Text>
        <Typography.Text
          style={{
            fontSize: 16,
          }}
        >
          Шукаємо...
        </Typography.Text>
      </Flex>
    );

  return (
    <Flex.Col gap={16} fullHeight>
      <Subheader hideFavourite />
      {foldersToRender.length === 0 && value && (
        <Flex.Col fullHeight align="center" justify="center">
          <Typography.Text>Не знайдено</Typography.Text>
        </Flex.Col>
      )}
      {foldersToRender.length !== 0 && (
        <Virtuoso
          style={{ height: "100%" }}
          data={foldersToRender}
          itemContent={(index, { icon, id, title, type }) => {
            const route =
              type === "folder"
                ? resolveRoute("folder", { id })
                : `${resolveRoute(type, { id })}?search=${value}`;
            return (
              <Fragment key={id}>
                <Link to={route} key={id}>
                  <Law hideFavourite title={title} />
                </Link>
                <div
                  style={{
                    height: 1,
                    width: "100%",
                    background: isDarkMode ? "#1D3B5D" : "#D9D9D9",
                  }}
                />
              </Fragment>
            );
          }}
        />
      )}
    </Flex.Col>
  );
};
