import { ReactNode, useEffect, useState } from "react";
import ReactDOM from "react-dom";

export type PortalProps = {
  id: string;
  children?: ReactNode;
};

export const Portal = ({ id, children }: PortalProps): JSX.Element => {
  const [parent, setParent] = useState<HTMLElement | null>(null);
  useEffect(() => {
    setParent(document.getElementById(id));
  }, [id]);

  if (!parent) return <></>;
  return <>{ReactDOM.createPortal(children, parent)}</>;
};
