import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { useHeaderValue } from "../ui/HeaderContext";
export const useSearch = (route: string) => {
  const [value] = useHeaderValue();
  const navigate = useNavigate();

  useEffect(() => {
    if (!value) return;
    navigate(`${routes.search}?search=${value}`);
  }, [value]);
};
