const removeSlash = (s: string): string => {
  const isStartsWithSlash = s.startsWith("/");
  const isEndsWithSlash = s.endsWith("/");
  if (!isStartsWithSlash && !isEndsWithSlash) return s;
  const startIndex = isStartsWithSlash ? 1 : 0;
  const endIndex = isEndsWithSlash ? -1 : s.length;
  return removeSlash(s.slice(startIndex, endIndex));
};

export const concatUrl = (...path: string[]): string =>
  path
    .filter(Boolean)
    .map((s) => removeSlash(s))
    .join("/");
