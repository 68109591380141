import { resolveRoute } from "../routes";

export const getSrcFromImage = (url: string) => {
  const src = url.slice("https://drive.google.com/file/d/".length);
  const json = localStorage.getItem(`image-${src}`);
  if (!json) return null;
  const imageData = JSON.parse(json);
  const { mimeType, data } = imageData;
  return getElSrcFromImageSrc(mimeType, data);
};

export const getElSrcFromImageSrc = (mimeType: string, data: string) =>
  `data:${mimeType};base64, ${data}`;

export const getAppLinkToLaw = (href: string) => {
  const url = new URL(href);
  if (url.origin !== process.env.REACT_APP_LAWS_ORIGIN) return;
  const pathname = url.pathname.split("/");
  return resolveRoute("law", { id: pathname.pop() as string }) + url.hash;
};
