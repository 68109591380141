import { DOCS_DRIVE_FOLDER } from "../App";
import { Database } from "../db/db";

export type Folders = {
  mimeType: string;
  icon?: { id: string; mimeType: string; data: string };
  title: string;
  id: string;
}[];

export const getFolder = async (id = DOCS_DRIVE_FOLDER): Promise<Folders> => {
  const db = Database.getInstance();
  // @ts-ignore
  const folder: any = await db.getById("folder", id);
  if (!folder) return [];
  const folders = await Promise.all(
    folder.children.flatMap(async (childId: any) => {
      const doc: any = await db.getById("doc", childId);
      if (doc) {
        return [
          {
            id: doc.id,
            title: doc.name,
            mimeType: doc.mimeType,
          },
        ];
      }
      const folder: any = await db.getById("folder", childId);
      if (!folder) return [];
      const icon = folder.icon
        ? await db.getById("image", folder.icon.src)
        : undefined;
      return [
        {
          icon,
          mimeType: folder.mimeType,
          title: folder.name,
          id: folder.id,
        },
      ];
    })
  );
  return folders.flat();
};
