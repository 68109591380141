import Icon from "@ant-design/icons";
import { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export const LogoDark = (
  props: Partial<CustomIconComponentProps>
): JSX.Element => (
  <Icon
    component={() => (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.1161 18.837C33.6589 19.4854 35.4531 17.596 34.6916 16.1322C34.0756 14.9487 33.2656 13.8693 32.5687 12.727C31.8234 11.5021 31.123 10.579 30.5357 9.28048C29.4634 3.7883 36.3673 3.01422 48 6.14824C47.7252 6.72656 47.5546 7.15401 47.3283 7.55092C41.5272 17.684 35.7387 27.8242 29.8927 37.9303C29.0378 39.4084 28.0248 40.8417 26.8646 42.0881C24.8836 44.2163 22.6799 44.2648 20.8175 42.0126C19.158 40.0047 17.7715 37.7292 16.5107 35.4357C11.164 25.7103 5.90885 15.9365 0.619618 6.17877C0.46157 5.88603 0.307117 5.59148 0 5.01137C4.99648 7.1989 9.63376 9.35411 14.3662 11.2686"
          fill="url(#paint0_linear_340_42480)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_340_42480"
            x1="0.000449001"
            y1="24.0013"
            x2="48.0005"
            y2="24.0013"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00A8D9" />
            <stop offset="0.3843" stopColor="#2780BD" />
            <stop offset="0.7852" stopColor="#4A5DA4" />
            <stop offset="1" stopColor="#574F9A" />
          </linearGradient>
        </defs>
      </svg>
    )}
    {...props}
  />
);
