import { SyncOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import Flex from "../utils/Flex";
import styles from "./LoaderScreen.module.scss";

export type LoaderScreenProps = HTMLAttributes<HTMLDivElement> & {};

export const LoaderScreen = ({
  className,
  ...props
}: LoaderScreenProps): JSX.Element => {
  return (
    <Flex
      col
      fullHeight
      fullWidth
      align="center"
      justify="center"
      gap={8}
      className={classNames(styles.loaderScreen, className)}
      {...props}
    >
      <SyncOutlined spin />
      <div
        style={{
          padding: "0px 16px",
        }}
      >
        Завантаження може зайняти декілька хвилин. Зачекайте, будь ласка...
      </div>
    </Flex>
  );
};
