import { useAsync } from "react-use";
import { ABOUT_DRIVE_FILE } from "../App";
import DocView from "../components/DocView";
import { getDoc } from "../googleAPI/getDoc";
import { useTheme } from "../ui/Theme";
import Flex from "../utils/Flex";

export const About = () => {
  const [isDark] = useTheme();
  const { value: item, loading } = useAsync(
    async () => getDoc(ABOUT_DRIVE_FILE),
    []
  );
  if (!item && !loading)
    return (
      <Flex
        fullHeight
        fullWidth
        justify="center"
        align="center"
        style={{ color: isDark ? "#fff" : undefined }}
      >
        Не знайдено
      </Flex>
    );
  if (!item) return <></>;
  return <DocView {...item} />;
};
