import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "antd/dist/reset.css";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { useNetworkState, useUpdateEffect } from "react-use";
import { Controls } from "././ui/Controls";
import "./App.css";
import { replace } from "./app/fetchSlice";
import { loadFavoriteLaws } from "./app/lawsSlice";
import { AppDrawer } from "./components/TreeDrawer";
import { Database } from "./db/db";
import { useHistory, useTrackHistory } from "./hooks/useHistory";
import { useRefresh } from "./hooks/useRefresh";
import { About } from "./pages/about";
import { Doc } from "./pages/doc";
import { Favourites } from "./pages/favourites";
import { Folders } from "./pages/folders";
import { Law } from "./pages/law";
import { Laws } from "./pages/laws";
import { Search } from "./pages/search";
import { Settings } from "./pages/settings";
import { resolveRoute, routes } from "./routes";
import { ConfirmFirstModal } from "./ui/ConfirmFirstModal";
import { Footer } from "./ui/Footer";
import { Header } from "./ui/Header";
import { LoaderScreen } from "./ui/LoaderScreen";
import { LoadingErrorAlert } from "./ui/LoadingErrorAlert";
import { useTheme } from "./ui/Theme";
import Flex from "./utils/Flex";
import { incrementLinksCount } from "./utils/incrementLinksCount";
import { Suspense } from "./utils/useSuspense";

const DAY = 1000 * 60 * 60 * 24;

export const ABOUT_DRIVE_FILE = process.env.REACT_APP_ABOUT_DOCUMENT;
export const LIBRARY_DRIVE_FOLDER = process.env.REACT_APP_LIBRARY_FOLDER;
export const DOCS_DRIVE_FOLDER = process.env.REACT_APP_TREE_FOLDER;
export const SUBHEADER_ID = "subheaderId";

const AppRoutesLayout = () => {
  const location = useLocation();
  return (
    <motion.div
      key={location.pathname}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.1 } }}
      exit={{ opacity: 0 }}
      style={{ width: "100%", height: "100%" }}
    >
      <Outlet />
    </motion.div>
  );
};

export default function App() {
  const location = useLocation();
  const ref = useRef<HTMLDivElement>(null);
  const [dark] = useTheme();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const dispatch = useDispatch();
  const { online } = useNetworkState();
  const [scrollUpVisible, setScrollUpVisible] = useState<boolean>(false);
  const [confirmVisible, setConfirmVisible] = useState<boolean>(true);
  const [{ state }, { refresh }] = useRefresh();
  const { goBack, canGoBack } = useHistory();
  useTrackHistory();

  // Disable IOS go back
  useEffect(() => {
    const element = document.querySelector("#root");
    const listener = (e: any) => {
      if (
        e.touches[0].clientX > 20 &&
        e.touches[0].clientX < window.innerWidth - 20
      )
        return;
      e.preventDefault();
    };
    if (!element) return;
    element.addEventListener("touchstart", listener);
    return () => element.removeEventListener("touchstart", listener);
  }, []);

  // Restore favorites
  useEffect(() => {
    dispatch(loadFavoriteLaws());
  }, []);

  // Restore Warning
  useEffect(() => {
    const errorLoading = localStorage.getItem("errorLoading");
    if (!errorLoading) {
      localStorage.setItem("errorLoading", "false");
    }
    if (errorLoading === "true") {
      dispatch(replace("error"));
    }
  }, []);

  useUpdateEffect(() => {
    const db = Database.getInstance();
    const { pathname } = location;
    const id = pathname.split("/").pop();
    if (!id) return;
    if (pathname.startsWith(routes.docBase)) {
      (async () => {
        const doc = await db.getById("doc", id);
        if (!doc) return;
        incrementLinksCount(JSON.stringify([doc.name, id]));
      })();
    }
    if (pathname.startsWith(routes.laws) && pathname !== routes.laws) {
      (async () => {
        const law = await db.getById("law", id);
        if (!law) return;
        incrementLinksCount(JSON.stringify([law.name, id]));
      })();
    }
    if (
      pathname.startsWith(routes.favourites) &&
      pathname !== routes.favourites
    ) {
      (async () => {
        const doc = await db.getById("doc", id);
        const law = await db.getById("law", id);
        if (!law && !doc) return;
        incrementLinksCount(JSON.stringify([law.name || doc.name, id]));
      })();
    }
  }, [location]);

  if (state === "uninitialized") {
    return (
      <>
        <ConfirmFirstModal
          visible={confirmVisible}
          onOk={() => {
            if (!online) {
              dispatch(replace("success"));
              return;
            }
            refresh();
          }}
          onCancel={() => {
            setConfirmVisible(false);
          }}
        />
      </>
    );
  }

  if (state === "uninitialized" || state === "pending") return <LoaderScreen />;

  const backInvisible =
    location.pathname === routes.folders ||
    location.pathname === routes.laws ||
    location.pathname === routes.favourites ||
    !canGoBack;

  return (
    <Flex.Col fullHeight className={classNames("../App", { dark })}>
      <LoadingErrorAlert visible={state === "error"} />
      <Header onBurgerClick={() => setDrawerOpen(true)} />
      <Flex.Row style={{ padding: "0px 8px" }}>
        <Flex.Item flex={0}>
          <Button
            size="large"
            className="backButton"
            style={{
              opacity: backInvisible ? 0 : 1,
              visibility: backInvisible ? "hidden" : "visible",
              marginLeft: backInvisible ? -28 : 0,
              transition: "all 0.2s ease-out",
            }}
            icon={<LeftOutlined />}
            type="text"
            onClick={() => {
              if (!canGoBack) return;
              goBack();
            }}
          />
        </Flex.Item>
        <Flex.Item flex={1} id={SUBHEADER_ID} />
      </Flex.Row>
      <AppDrawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />
      <Flex.Item
        flex={1}
        fixedHeight
        style={{ position: "relative", marginTop: 8 }}
      >
        <div
          ref={ref}
          onScroll={(e: any) => {
            setScrollUpVisible(e.target.offsetHeight + 10 < e.target.scrollTop);
          }}
          className="appBody"
        >
          <Suspense>
            <AnimatePresence mode="wait">
              <Routes location={location} key={location.pathname}>
                <Route element={<AppRoutesLayout />}>
                  <Route path={routes.folders} element={<Folders />} />
                  <Route path={routes.folder} element={<Folders />} />
                  <Route path={routes.doc} element={<Doc />} />
                  <Route path={routes.favourites} element={<Favourites />} />
                  <Route path={routes.law} element={<Law />} />
                  <Route path={routes.laws} element={<Laws />} />
                  <Route path={routes.search} element={<Search />} />
                  <Route path={routes.settings} element={<Settings />} />
                  <Route path={routes.about} element={<About />} />
                  <Route
                    path={routes.library}
                    element={
                      <Navigate
                        to={resolveRoute("folder", {
                          id: LIBRARY_DRIVE_FOLDER as string,
                        })}
                        replace
                      />
                    }
                  />
                  <Route path="*" element={<Navigate to={routes.folders} />} />
                </Route>
              </Routes>
            </AnimatePresence>
          </Suspense>
        </div>
        <Controls
          onlyScrollUp
          scrollUpVisible={scrollUpVisible}
          onScrollUp={() => ref.current?.scroll({ top: 0, behavior: "smooth" })}
          syncAllowed={online}
        />
      </Flex.Item>
      <Footer />
    </Flex.Col>
  );
}
