import { ConfigProvider, theme } from "antd";
import { ConfigProviderProps } from "antd/es/config-provider";
import classNames from "classnames";
import styles from "./Theme.module.scss";
import React from "react";

const GENRAL_THEME_CONFIG = {
  colorPrimary: "#3972AC",
  borderRadius: 2,
};
const LIGHT_THEME_CONFIG = {
  colorText: "rgba(6, 12, 19, 0.88)",
  colorBgContainer: "#FFFFFF",
};
const DARK_THEME_CONFIG = {
  colorText: "rgba(255, 255, 255, 0.85)",
  colorBgContainer: "#14191F",
};

const { defaultAlgorithm, darkAlgorithm } = theme;

const ThemeContext = React.createContext<{
  isDarkMode: boolean;
  setIsDarkMode: (mode: boolean) => void;
}>({
  isDarkMode: localStorage.getItem("theme") === "dark",
  setIsDarkMode: () => null,
});

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  return [context.isDarkMode, context.setIsDarkMode] as const;
};

export const ThemeContextProvider = ({
  children,
  theme,
  ...props
}: ConfigProviderProps) => {
  const [isDarkMode, setIsDarkMode] = React.useState(
    localStorage.getItem("theme") === "dark"
  );
  const currentThemeConfig = isDarkMode
    ? DARK_THEME_CONFIG
    : LIGHT_THEME_CONFIG;

  return (
    <ThemeContext.Provider value={{ isDarkMode, setIsDarkMode }}>
      <ConfigProvider
        theme={{
          algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
          token: {
            ...GENRAL_THEME_CONFIG,
            ...currentThemeConfig,
          },
          ...theme,
        }}
        {...props}
      >
        <div
          className={classNames(styles.themeBody, {
            [styles.dark]: isDarkMode,
          })}
        >
          {children}
        </div>
      </ConfigProvider>
    </ThemeContext.Provider>
  );
};
