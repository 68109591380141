import classNames from "classnames";
import { ReactNode } from "react";
import styles from "./Anchor.module.scss";

export interface AnchorProps {
  children?: ReactNode;
  left?: boolean;
  onClick?: () => void;
}

export const Anchor = ({
  children,
  onClick,
  left,
}: AnchorProps): JSX.Element => {
  return (
    <div
      className={classNames(styles.anchor, {
        [styles.left]: left,
      })}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
