import { SettingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useAsync } from "react-use";
import { LIBRARY_DRIVE_FOLDER } from "../App";
import { Database } from "../db/db";
import { getDriveTree } from "../googleAPI/getDriveTree";
import { FolderFallback } from "../icons/FolderFallback";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import { ReactComponent as LibraryIcon } from "../icons/library.svg";
import { ReactComponent as LikeIcon } from "../icons/like.svg";
import { resolveRoute, routes } from "../routes";
import { Drawer } from "../ui/Drawer";
import { useTheme } from "../ui/Theme";
import { Tree } from "../ui/Tree";
import { getElSrcFromImageSrc } from "../utils";
import Flex from "../utils/Flex";
import styles from "./TreeDrawer.module.scss";

const getMobileOperatingSystem = () => {
  const isIOS =
    // @ts-ignore
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  if (isIOS) return "IOS";
  const isAndroid = /Android/.test(navigator.userAgent);
  if (isAndroid) return "Android";
  return "unknown";
};

const TitleRender = ({ node, onClick }: any) => {
  const { value: folder } = useAsync(async () => {
    const db = Database.getInstance();
    return db.getById("folder", node.value.id);
  }, [node.value.id]);
  const { value: icon } = useAsync(async () => {
    if (!folder?.icon?.src) return null;
    const db = Database.getInstance();
    return db.getById("image", folder.icon.src);
  }, [folder?.icon?.src]);
  const _icon =
    node.value.mimeType === "application/vnd.google-apps.folder" ? (
      icon ? (
        <img src={getElSrcFromImageSrc(icon.mimeType, icon.data)} />
      ) : (
        <FolderFallback />
      )
    ) : undefined;
  const match = node.value.name.match(new RegExp("[0-9]*_"));
  const title = !match
    ? node.value.name
    : node.value.name.slice(match[0].length);

  const type =
    node.value.mimeType === "application/vnd.google-apps.document"
      ? "doc"
      : "folder";

  return (
    <Link to={resolveRoute(type, { id: node.value.id })} onClick={onClick}>
      <Flex
        // align="center"
        gap={8}
        className={styles.treeTitle}
        style={{ whiteSpace: "break-spaces" }}
      >
        {_icon && (
          <Flex.Row className={styles.icon} align="center" justify="center">
            {_icon}
          </Flex.Row>
        )}
        <div className={styles.title}>{title}</div>
      </Flex>
    </Link>
  );
};

const AppDrawerTree = ({ onClick }: { onClick?: () => void }) => {
  const tree = getDriveTree();

  return (
    <Tree
      treeData={tree}
      titleRender={(node: any) => <TitleRender node={node} onClick={onClick} />}
    />
  );
};

export const AppDrawer = (props: { open: boolean; onClose: () => void }) => {
  const [dark] = useTheme();
  const { value: link } = useAsync(async () => {
    const db = Database.getInstance();
    const doc = await db.getById("doc", process.env.REACT_APP_RATE_DOC || "");
    if (!doc) return "";
    const [IOS, Android] = doc.doc
      .replaceAll(" ", "")
      .replace("ANDROID:", "")
      .replace("IOS:", "")
      .split(/\r?\n/);

    const system = getMobileOperatingSystem();
    if (system === "IOS") return IOS;
    if (system === "Android") return Android;
    return "";
  }, []);

  return (
    <Drawer className={classNames({ dark })} {...props}>
      <Flex.Item
        flex={1}
        className={styles.drawerTree}
        style={{ marginLeft: "-24px" }}
      >
        <AppDrawerTree onClick={props.onClose} />
      </Flex.Item>
      <Flex.Col gap={16}>
        <Link to={routes.settings} onClick={props.onClose}>
          <Button
            className={classNames(styles.drawerButton, {
              [styles.dark]: dark,
            })}
            type="text"
            block
            icon={<SettingOutlined />}
          >
            Налаштування
          </Button>
        </Link>
        <Link
          // @ts-ignore
          to={resolveRoute("folder", { id: LIBRARY_DRIVE_FOLDER })}
          onClick={props.onClose}
        >
          <Button
            className={classNames(styles.drawerButton, {
              [styles.dark]: dark,
            })}
            type="text"
            block
            icon={<LibraryIcon />}
          >
            Бібліотека
          </Button>
        </Link>
        <Link to={resolveRoute("about", {})} onClick={props.onClose}>
          <Button
            className={classNames(styles.drawerButton, {
              [styles.dark]: dark,
            })}
            type="text"
            block
            icon={<InfoIcon />}
          >
            Про застосунок
          </Button>
        </Link>
        <a href={link} target="_blank" rel="noreferrer">
          <Button
            className={classNames(styles.drawerButton, {
              [styles.dark]: dark,
            })}
            type="text"
            block
            icon={<LikeIcon />}
          >
            Оцінити
          </Button>
        </a>
      </Flex.Col>
    </Drawer>
  );
};
