import { DownOutlined } from "@ant-design/icons";
import { Tree as AntTree, TreeProps as AntTreeProps } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTheme } from "./Theme";
import styles from "./Tree.module.scss";

export interface TreeProps extends AntTreeProps {}

export const Tree = ({ className, ...props }: TreeProps): JSX.Element => {
  const [isDarkMode] = useTheme();

  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);

  return (
    <AntTree
      className={classNames(
        styles.tree,
        { [styles.dark]: isDarkMode },
        className
      )}
      switcherIcon={<DownOutlined />}
      expandedKeys={expandedKeys}
      onSelect={(_, e) => {
        const { key } = e.node;
        setExpandedKeys((expandedKeys) =>
          expandedKeys.includes(key as string)
            ? expandedKeys.filter((entry) => entry !== key)
            : [...expandedKeys, key as string]
        );
      }}
      onExpand={(_, e) => {
        const { key } = e.node;
        setExpandedKeys((expandedKeys) =>
          expandedKeys.includes(key as string)
            ? expandedKeys.filter((entry) => entry !== key)
            : [...expandedKeys, key as string]
        );
      }}
      motion={false}
      {...props}
    />
  );
};
