import { DOCS_DRIVE_FOLDER } from "../App";
import { Folders } from "../googleAPI/getFolder";
import { FolderFallback } from "../icons/FolderFallback";
import { useLocation, useNavigate } from "react-router-dom";
import { resolveRoute } from "../routes";
import { Panel } from "../ui/Panel";
import { Subheader } from "../ui/Subheader";
import { getElSrcFromImageSrc } from "../utils";
import Flex from "../utils/Flex";

const DEBOUNCE_DELAY = 300;

const FOLDER_MIME_TYPE = "application/vnd.google-apps.folder";

export default ({
  parentId,
  folders,
}: {
  parentId?: string;
  folders: Folders;
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const foldersToRender = folders
    .sort(({ title: a = "" }, { title: b = "" }) => {
      const matchA = a.match(new RegExp("[0-9]*_"));
      const matchB = b.match(new RegExp("[0-9]*_"));
      if (!!matchA && !matchB) return -1;
      if (!matchA && !!matchB) return 1;
      if (!!matchA && !!matchB) {
        const [textA] = matchA;
        const [textB] = matchB;
        const textAMapped = Number(textA.slice(0, textA.length - 1)) || 0;
        const textBMapped = Number(textB.slice(0, textB.length - 1)) || 0;
        return textAMapped - textBMapped;
      }
      return a.localeCompare(b);
    })
    .map(({ icon, id, title, mimeType }) => {
      const match = title.match(new RegExp("[0-9]*_"));
      const _title = !match ? title : title.slice(match[0].length);
      const _icon =
        mimeType === FOLDER_MIME_TYPE ? (
          icon ? (
            <img src={getElSrcFromImageSrc(icon.mimeType, icon.data)} />
          ) : (
            <FolderFallback />
          )
        ) : undefined;
      return {
        id,
        title: _title,
        icon: _icon,
        mimeType,
      };
    });

  return (
    <Flex.Col gap={16}>
      <Subheader hideFavourite />
      {parentId !== DOCS_DRIVE_FOLDER &&
        foldersToRender.map(({ icon, id, title, mimeType }) => {
          const route = mimeType === FOLDER_MIME_TYPE ? "folder" : "doc";
          const link = resolveRoute(route, { id });
          return (
            <Flex.Item key={id} flex={1}>
              <Panel
                title={title}
                icon={icon}
                link={link}
                onClick={() =>
                  navigate(`${location.pathname}${location.search}`)
                }
              />
            </Flex.Item>
          );
        })}
      {parentId === DOCS_DRIVE_FOLDER && (
        <Flex.Row gap={16} wrap>
          {foldersToRender.map(({ icon, id, title, mimeType }) => {
            const route = mimeType === FOLDER_MIME_TYPE ? "folder" : "doc";
            const link = resolveRoute(route, { id });

            return (
              <Flex.Col
                key={id}
                flex={1}
                style={{ minWidth: "calc(50% - 8px)" }}
              >
                <Panel
                  title={title}
                  icon={icon}
                  link={link}
                  zipTitle
                  onClick={() =>
                    navigate(`${location.pathname}${location.search}`)
                  }
                />
              </Flex.Col>
            );
          })}
        </Flex.Row>
      )}
    </Flex.Col>
  );
};
